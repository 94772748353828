<!-- Navbar -->
<app-navbar-style-two></app-navbar-style-two>
<!-- End Navbar -->

<!-- Banner -->
<!--section class="banner-area bg-1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="banner-content">
                    <h1 class="wow fadeInDown" data-wow-delay="1s">Our priority is to ensure the well being of patients</h1>
                    <p class="wow fadeInLeft" data-wow-delay="1s">Our service and the work efficiency of our staff are the reasons for our success.</p>
                    <div class="banner-btn wow fadeInUp" data-wow-delay="1s">
                        <a routerLink="/about" class="default-btn">View more</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section-->
<!-- End Banner -->

<!-- Feature -->
<!--div class="feature-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-feature">
                    <i class="flaticon-doctor-1"></i>
                    <span>Find a doctor</span>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-feature active">
                    <img src="assets/img/icon/appointment.png" alt="Image">
                    <span>Request an appointment</span>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-feature">
                    <i class="flaticon-first-aid-kit"></i>
                    <span>Emergency case</span>
                </div>
            </div>
        </div>
    </div>
</div-->
<!-- End Feature -->


<!--div class="coming-soon-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="coming-soon-content">
                <a routerLink="/" class="logo"><img src="assets/img/logo.png" alt="image"></a>
                <h2>Site en construction!</h2>
            </div>
        </div>
    </div>
    <div class="online-treatment-shape-1"><img src="assets/img/online-treatment-shape-1.png" alt="Image"></div>
    <div class="online-treatment-shape-2"><img src="assets/img/online-treatment-shape-2.png" alt="Image"></div>
</div-->

<!-- Choose Us -->
<section class="choose-us-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Qui suis-je?</span>
            <h2>Praticien Naturopathe Vitaliste</h2>
            <p><strong>- Certifié à la FENA (Fédération Fançaise de Naturopathie)</strong></p>
            <p><strong>- Adhérent à l'OMNES (Organisation de la Médecine Naturelle et de l’Éducation Sanitaire)</strong></p>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="choose-us-img">
                    <img src="assets/img/choose-us-img1.png" alt="Image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="choose-us-content">
                    <h3>Je suis Sané</h3>
                    <p>
                        <strong>Praticien Naturopathe Vitaliste</strong> diplômé de l'école DARGERE Univers.
                        Mon objectif est de vous guider sur le chemin de l'équilibre en utilisant les principes de la
                        Naturopathie.
                    <p>
                        Mon approche en tant que naturopathe se concentre sur les quatres domaines clés de l'hygiène viatale:
                        alimentaire, émonctorielle, musculaire et nerveuse. En travaillant sur ces aspects,
                        je vous aide à devenir un acteur essentiel de votre propre santé et bien-être.
                        Mon engagement est de vous aider à atteindre un état de santé optimal en utilisant des
                        méthodes naturelles et en vous encourageant à prendre des mesures proactives pour votre
                        bien-être global. Le Praticien Naturopathe Vitaliste n’est ni médecin, ni guérisseur mais
                        Éducateur. Je serais heureux de vous accompagner pour vous transmettre les lois de la VIE
                        enseignées par <strong>Pierre Valentin Marchesseau</strong>.
                    </p>

                    <p>Je vous accompagne avec une immense JOIE sur le chemin de votre autonomie. C'est vrai que la santé est avant
                        tout une question d'<strong>autonomie</strong> et de <strong>responsabilité</strong>.
                    </p>

                    <p>Alors Bienvenu dans <strong>HYGINAT</strong>! pour mettre en place les hygiènes Naturopatiques.</p>
                </div>
            </div>
        </div>
    </div>

    <div class="row align-items-center" style="background-color: white;">
        <div class="col-lg-6 col-md-12">
            <div class="choose-us-img">
                <img height="500" width="500" src="assets/img/icon/logo_certifie_fena.PNG" alt="Image">
            </div>
        </div>

        <div class="col-lg-6 col-md-12">
            <div class="choose-us-img">
                <img height="500" width="500" src="assets/img/icon/logo_omnes.PNG" alt="Image">
            </div>
        </div>
    </div>



    <div class="choose-us-shape"><img src="assets/img/choose-us-shape.png" alt="Image"></div>
</section>
<!-- End Choose Us -->


<!-- Start Choose Us -->
<section class="choose-us-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Mes compétences</span>
            <h2>La Naturopathie est l'art de favoriser l'autoguérison avec ses 10 techniques naturelles.</h2>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="choose-us-img">
                    <img src="assets/img/nat.png" alt="Image">
                </div>

            </div>

            <div class="col-lg-6 col-md-12">
                <div class="choose-us-content-two">
                    <p>
                        Nous travaillons sur les 4 corps énergétiques qui compose l'être humain dans sa
                        globalité: <strong>approche holistique de la santé</strong></p>
                    <ul>
                        <li><span>1</span> Le corps physique</li>
                        <li><span>2</span> Le corps vital</li>
                        <li><span>3</span> Le corps mental</li>
                        <li><span>4</span> Le corps spirituel</li>
                    </ul>
                    <!--a routerLink="/" class="default-btn">En savoir plus sur la naturopathie</a-->
                </div>
            </div>
        </div>
        <p>Nous allons renforcer ces 4 corps énergétiques avec les 10 techniques naturelles.</p>

        <p>Pour cela nous disposons d'un protocole de 3 cures et de 4 hygiènes à mettre en place</p>

        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="choose-us-img">
                    <img src="assets/img/hyg.png" alt="Image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="choose-us-img">
                    <img src="assets/img/cure.png" alt="Image">
                </div>
            </div>
        </div>
    </div>

    <div class="choose-us-shape"><img src="assets/img/choose-us-shape.png" alt="Image"></div>
</section>
<!-- End Choose Us -->

<!-- Appointment -->
<!--section class="appointment-area">
    <div class="container">
        <div class="appointment-here-form">
            <form>
                <div class="row align-items-center">
                    <div class="col-lg-3 col-sm-6">
                        <h3>Book an appointment</h3>
                    </div>

                    <div class="col-lg-3 col-sm-6">
                        <div class="form-group">
                            <select>
                                <option value="">Doctor Name</option>
                                <option value="">Dr.Juhon Dew</option>
                                <option value="">Dr.Jon Smith Care</option>
                                <option value="">Dr.Ana</option>
                                <option value="">Dr.Kilva</option>
                                <option value="">Dr.Zilka Smith</option>
                                <option value="">Dr.Jilva Dew</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-lg-3 col-sm-6">
                        <div class="form-group">
                            <div class="input-group date" id="datetimepicker">
                                <input type="text" class="form-control" placeholder="07/09/2020">
                                <span class="input-group-addon"></span>
                            </div>
                            <i class="bx bx-calendar"></i>
                        </div>
                    </div>

                    <div class="col-lg-3 col-sm-6">
                        <button type="submit" class="default-btn">Book Now</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section-->
<!-- End Appointment -->




<!-- Services -->



<section class="services-area pt-100 pb-70">
    <div class="container">
            <div class="section-title text-align-left">
                <span>Mes services</span>
                <h2>Je vous accompagne avec plein de techniques  de la naturopathie Vitaliste, dans une démarche de santé holistique. Je vous présente
                dessous quelques unes de ces techniques avec lesquelles je travaille.</h2>
            </div>

        <div class="row">

            <div class="col-lg-3 col-sm-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/icon/alim.png" alt="Image">
                    </div>
                    <h3>Nutrition</h3>
                    <p><strong>« Que ta nourriture soit ton médicament et que ton médicament soit dans ta nourriture »</strong>
                        Hippocrate. C'est la première hygiène à adopter. Connaitre et respecter les lois de la physiologie alimentaire
                        est le secret si on veut avoir une bonne santé physique.</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/icon/cc.png" alt="Image">
                    </div>
                    <h3><a routerLink="/cc-service">Cohérence cardiaque</a></h3>
                    <p>Pour mieux gérer le stress avec la respiration. La cohérence cardiaque harmonise la biochimie du corps
                        et nous amène à la neutralité émotionnelle du moment. J'utilise le <strong>biofeedback</strong> pour
                        suivre l'évolution.</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/icon/bach.png" alt="Image">
                    </div>
                    <h3><a routerLink="/bach-service">Fleurs de Bach et quantiques olfactifs</a></h3>
                    <p>La gestion des émotions et du mental est d'une importance capitale sur le chemin de la santé.
                        Les fleurs de Bach et les quantiques olfactifs permettent d'avoir des résultats en profondeur
                        sur le travail des émotions</p>
                </div>
            </div>


            <div class="col-lg-3 col-sm-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/icon/c3050.png" alt="Image">
                    </div>
                    <h3><a routerLink="/bev-service">Bioelectronique de Vincent</a></h3>
                    <p>Pour connaitre l'état de votre terrain mesurable avec les trois paramètres de la bioélectronique
                        de Vincent : le potentiel Hydrogène (<strong>ph</strong>), le potentiel d'oxydo réduction (<strong>rh2</strong>) et la résistivité électrique (<strong>rô</strong>).</p>
                </div>
            </div>


            <!--div class="col-lg-3 col-sm-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/dyno.png" alt="Image">
                    </div>
                    <h3><a routerLink="/irido-service">Iridologie</a></h3>
                    <p>Faire un bilan iridologique, c'est faire parler vos iris, ils ont beaucoup d'informations.
                    C'est un outil formidable qui nous donne le niveau de toxémie, la vitalité, la valeur des émonctoires, mais aussi
                    le terrain et les prédispositions</p>
                </div>
            </div-->

            <!--div class="col-lg-3 col-sm-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/jacquier.png" alt="Image">
                    </div>
                    <h3><a routerLink="/jacquier-service">Bol d'air Jacquier</a></h3>
                    <p>Le Bol d’air optimise l’oxygénation cellulaire. Il augmente et entretient notre faculté
                        d’assimilation de l’oxygène normalement respiré. Le Bol d’air contribue ainsi à préserver un bon
                        état de santé. Efficace pour la récupération des sportifs.</p>
                </div>
            </div-->


            <!--div class="col-lg-3 col-sm-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/zen.png" alt="Image">
                    </div>
                    <h3><a routerLink="/relaxation-service">Relaxation</a></h3>
                    <p>Techniques de Respiration (Cohérence Cardioaque, respiration 478, respiration carrée, ...),
                        de relaxation, de méditations, développement personnelle, puissance de la pensée, visualisation créatrice,
                    EFT, Ho'oponopono, Analyse transactionnelle, OSBD, ...</p>
                </div>
            </div-->

            <!--div class="col-lg-3 col-sm-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/vent.png" alt="Image">
                    </div>
                    <h3><a routerLink="/ventouse-service">Ventouses</a></h3>
                    <p>Un des outils puissant de la réflexologie. Elles permettent entre autres de faire circuler les énergies.
                        Dès fois certaines tensions bloquent le passage de l'énergie. Et quand l'énergie ne circulle pas, il ne peut y avoir d'autoguerrisons ...</p>
                </div>
            </div-->

        </div>

    </div>

    <div class="services-shape"><img src="assets/img/services-shape.png" alt="Image"></div>
</section>
<!-- End Services -->

<!-- Testimonial -->
<!-- section class="testimonial-area ptb-100">
    <div class="container">
        <div class="section-title text-align-left">
            <span>Quelques retours</span>
            <h2>Ils m'ont fait confiance. Merci à eux!</h2>
        </div>

        <div class="testimonial-slider owl-theme owl-carousel">
            <div class="row align-items-center">
                <div class="col-lg-4">
                    <div class="testimonial-img">
                        <img src="assets/img/testimo1.png" alt="Image">
                        <div class="testimonial-shape">
                            <img src="assets/img/testimonial/testimonial-shape.png" alt="Image">
                        </div>
                    </div>
                </div>

                <div class="col-lg-8">
                    <div class="testimonial-content">
                        <i class="flaticon-straight-quotes"></i>
                        <p>“Juste un grand Merci pour le travail que nous avons fait ensemble”</p>
                        <h3>Sandrine D.</h3>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-4">
                    <div class="testimonial-img">
                        <img src="assets/img/testimo1.png" alt="Image">
                        <div class="testimonial-shape">
                            <img src="assets/img/testimonial/testimonial-shape.png" alt="Image">
                        </div>
                    </div>
                </div>

                <div class="col-lg-8">
                    <div class="testimonial-content">
                        <i class="flaticon-straight-quotes"></i>
                        <p>“J'ai adoré la douceur des pratiques et l'écoute.”</p>
                        <h3>Allan G.</h3>
                    </div>
                </div>
            </div>


        </div>
    </div>
</section -->
<!-- End Testimonial -->


<!-- Testimonial -->
<!--section class="testimonial-area pb-100">
    <div class="container">
        <div class="section-title text-align-left">
            <span>Testimonial</span>
            <h2>What do our patients say?</h2>
        </div>

        <div class="testimonial-slider owl-theme owl-carousel">
            <div class="row align-items-center">
                <div class="col-lg-4 col-md-12">
                    <div class="testimonial-img">
                        <img src="assets/img/testimonial/testimonial-1.jpg" alt="Image">
                        <div class="testimonial-shape">
                            <img src="assets/img/testimonial/testimonial-shape.png" alt="Image">
                        </div>
                    </div>
                </div>

                <div class="col-lg-8 col-md-12">
                    <div class="testimonial-content">
                        <i class="flaticon-straight-quotes"></i>
                        <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.”</p>
                        <h3>Markus Twain</h3>
                        <img src="assets/img/testimonial/signature.png" alt="Image">
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-4 col-md-12">
                    <div class="testimonial-img">
                        <img src="assets/img/testimonial/testimonial-2.jpg" alt="Image">
                        <div class="testimonial-shape">
                            <img src="assets/img/testimonial/testimonial-shape.png" alt="Image">
                        </div>
                    </div>
                </div>

                <div class="col-lg-8 col-md-12">
                    <div class="testimonial-content">
                        <i class="flaticon-straight-quotes"></i>
                        <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.”</p>
                        <h3>Anna Deo</h3>
                        <img src="assets/img/testimonial/signature.png" alt="Image">
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-4 col-md-12">
                    <div class="testimonial-img">
                        <img src="assets/img/testimonial/testimonial-3.jpg" alt="Image">
                        <div class="testimonial-shape">
                            <img src="assets/img/testimonial/testimonial-shape.png" alt="Image">
                        </div>
                    </div>
                </div>

                <div class="col-lg-8 col-md-12">
                    <div class="testimonial-content">
                        <i class="flaticon-straight-quotes"></i>
                        <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.”</p>
                        <h3>Kilvis Smith</h3>
                        <img src="assets/img/testimonial/signature.png" alt="Image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section-->
<!-- End Testimonial -->

<!-- Doctors -->
<!--section class="doctors-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-align-left">
            <span>Our specialists</span>
            <h2>We have specialist doctor’s for emergency services</h2>
        </div>

        <div class="doctors-slider owl-theme owl-carousel">
            <div class="single-doctors one">
                <div class="doctors-img">
                    <img src="assets/img/doctors/doctors-1.jpg" alt="Image">
                    <ul>
                        <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-pinterest-alt"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                    </ul>
                </div>
                <div class="doctors-content">
                    <span>Cosmetic Surgeon</span>
                    <h3>Bernarda N. Bacon</h3>
                </div>
            </div>

            <div class="single-doctors two">
                <div class="doctors-img">
                    <img src="assets/img/doctors/doctors-2.jpg" alt="Image">
                    <ul>
                        <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-pinterest-alt"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                    </ul>
                </div>
                <div class="doctors-content">
                    <span>Surgery Surgeon</span>
                    <h3>Brian Christensen</h3>
                </div>
            </div>

            <div class="single-doctors three">
                <div class="doctors-img">
                    <img src="assets/img/doctors/doctors-3.jpg" alt="Image">
                    <ul>
                        <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-pinterest-alt"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                    </ul>
                </div>
                <div class="doctors-content">
                    <span>Dental surgeon</span>
                    <h3>Aimee C. Mayfield</h3>
                </div>
            </div>

            <div class="single-doctors four">
                <div class="doctors-img">
                    <img src="assets/img/doctors/doctors-4.jpg" alt="Image">
                    <ul>
                        <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-pinterest-alt"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                    </ul>
                </div>
                <div class="doctors-content">
                    <span>Cardiothoracic Surgeon</span>
                    <h3>Gabriel Henderson</h3>
                </div>
            </div>

            <div class="single-doctors five">
                <div class="doctors-img">
                    <img src="assets/img/doctors/doctors-5.jpg" alt="Image">
                    <ul>
                        <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-pinterest-alt"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                    </ul>
                </div>
                <div class="doctors-content">
                    <span>Surgeon</span>
                    <h3>Kilva Henderson</h3>
                </div>
            </div>

            <div class="single-doctors six">
                <div class="doctors-img">
                    <img src="assets/img/doctors/doctors-6.jpg" alt="Image">
                    <ul>
                        <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-pinterest-alt"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                    </ul>
                </div>
                <div class="doctors-content">
                    <span>Cardiology</span>
                    <h3>Ana Henderson</h3>
                </div>
            </div>
        </div>
    </div>
</section-->
<!-- End Doctors -->

<!-- Portfolio -->
<!--section class="our-portfolio-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="section-title text-align-left">
                    <span>Our portfolio</span>
                    <h2>Below are some examples of all the portfolios we provide to our patients</h2>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="section-btn">
                    <a routerLink="/portfolio-column-three" class="default-btn">View all portfolios</a>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-portfolio">
                    <img src="assets/img/portfolio/portfolio-1.jpg" alt="Image">
                    <div class="portfolio-content">
                        <h3><a routerLink="/portfolio-details">Conditions we treat</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-portfolio">
                    <img src="assets/img/portfolio/portfolio-2.jpg" alt="Image">
                    <div class="portfolio-content">
                        <h3><a routerLink="/portfolio-details">Research and innovation</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                <div class="single-portfolio">
                    <img src="assets/img/portfolio/portfolio-3.jpg" alt="Image">
                    <div class="portfolio-content">
                        <h3><a routerLink="/portfolio-details">Doctor examining physiotherapy </a></h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section-->
<!-- End Portfolio -->

<!-- Start Partner -->
<!--div class="partner-area tb-100">
    <div class="container">
        <div class="partner-slider partner-bg owl-theme owl-carousel">
            <div class="partner-item">
                <a href="#" target="_blank"><img src="assets/img/partner/partner-1.png" alt="Image"></a>
            </div>
            <div class="partner-item">
                <a href="#" target="_blank"><img src="assets/img/partner/partner-2.png" alt="Image"></a>
            </div>
            <div class="partner-item">
                <a href="#" target="_blank"><img src="assets/img/partner/partner-3.png" alt="Image"></a>
            </div>
            <div class="partner-item">
                <a href="#" target="_blank"><img src="assets/img/partner/partner-4.png" alt="Image"></a>
            </div>
            <div class="partner-item">
                <a href="#" target="_blank"><img src="assets/img/partner/partner-5.png" alt="Image"></a>
            </div>
            <div class="partner-item">
                <a href="#" target="_blank"><img src="assets/img/partner/partner-3.png" alt="Image"></a>
            </div>
        </div>
    </div>

    <div class="partner-shape"><img src="assets/img/partner/partner-shape.png" alt="Image"></div>
</div-->
<!-- End Partner -->

<!-- Blog -->
<!--section class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="section-title text-align-left">
                    <span>Recent news</span>
                    <h2>All the recent news about our treatment has been published</h2>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="section-btn">
                    <a routerLink="/blog-column-one" class="default-btn">View all news</a>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="blog-img">
                        <a routerLink="/blog-details"><img src="assets/img/blog/blog-1.jpg" alt="Image"></a>
                        <div class="date">
                            <ul>
                                <li>07</li>
                                <li>Sep</li>
                            </ul>
                        </div>
                    </div>
                    <div class="blog-content">
                        <span>General news</span>
                        <h3><a routerLink="/blog-details">It might be time to seek a medical your muscle and joint pain</a></h3>
                        <ul>
                            <li>By: <a routerLink="/">Curtis Warren</a></li>
                            <li class="read"><a routerLink="/blog-details">Read More <i class="flaticon-right-arrow"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="blog-img">
                        <a routerLink="/blog-details"><img src="assets/img/blog/blog-2.jpg" alt="Image"></a>
                        <div class="date">
                            <ul>
                                <li>08</li>
                                <li>Sep</li>
                            </ul>
                        </div>
                    </div>
                    <div class="blog-content">
                        <span>General news</span>
                        <h3><a routerLink="/blog-details">The reassurance of a safe pregnancy through the birth of your baby</a></h3>
                        <ul>
                            <li>By: <a routerLink="/">James Lopez</a></li>
                            <li class="read"><a routerLink="/blog-details">Read More <i class="flaticon-right-arrow"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="single-blog-post">
                    <div class="blog-img">
                        <a routerLink="/blog-details"><img src="assets/img/blog/blog-3.jpg" alt="Image"></a>
                        <div class="date">
                            <ul>
                                <li>09</li>
                                <li>Sep</li>
                            </ul>
                        </div>
                    </div>
                    <div class="blog-content">
                        <span>General news</span>
                        <h3><a routerLink="/blog-details">Read up on different types of fevers that may require a doctor</a></h3>
                        <ul>
                            <li>By: <a routerLink="/">Milton Baines</a></li>
                            <li class="read"><a routerLink="/blog-details">Read More <i class="flaticon-right-arrow"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section-->
<!-- End Blog -->

<!-- Subscribe -->
<!--section class="subscribe-area pb-100">
    <div class="container">
        <div class="subscribe-bg">
            <div class="row align-items-center">
                <div class="col-lg-6 col-sm-6">
                    <div class="subscribe-content">
                        <img src="assets/img/subscribe-img.png" alt="Image">
                        <h3>Sing up for newsletter</h3>
                        <span>Subscribe to the newsletter for all the latest updates</span>
                    </div>
                </div>

                <div class="col-lg-6 col-sm-6">
                    <form class="newsletter-form">
                        <input type="email" class="form-control" placeholder="Enter email address" name="email">
                        <button class="default-btn" type="submit">Subscribe</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section-->
<!-- End Subscribe -->

<!-- Footer -->
<app-footer-style-one></app-footer-style-one>
<!-- End Footer -->
