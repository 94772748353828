<!-- Navbar -->
<app-navbar-style-two></app-navbar-style-two>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Tarifs</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li class="active">Tarifs</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Pricing -->
<section class="pricing-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Tarifs et services</span>
            <h2>Je vous propose les services suivants</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-pricing active">
                    <h3>Première consultation</h3>
                    <h2>95<Sub>€</Sub></h2>
                    <span>1h30</span>
                    <h4>Détail</h4>

                    <ul>
                        <li>- Prise de contact</li>
                        <li>- Bilan de vitalité</li>
                    </ul>

                    <p>C'est la consultation la plus importante qui va permettre au praticien de connaître le consultant au mieux</p>

                    <!--a routerLink="/" class="default-btn">Make payment</a-->
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-pricing">
                    <h3>Suivi consultation</h3>
                    <h2>65<Sub>€</Sub></h2>
                    <span>1h00</span>
                    <h4>Détail</h4>

                    <ul>
                        <li>- Suivi</li>
                        <li>- Bilan de vitalité</li>
                    </ul>

                    <p>On fait le bilan des hygiènes préconisés et on adapte les conseils.</p>

                    <!--a routerLink="/" class="default-btn">Make payment</a-->
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-pricing">
                    <h3>Téléconsultation</h3>
                    <h2>50<Sub>€</Sub></h2>
                    <span>0h45</span>
                    <h4>Détail</h4>

                    <ul>
                        <li>- Suivi</li>
                        <li>- Bilan de vitalité</li>
                    </ul>

                    <p>On fait le bilan des hygiènes préconisés et on adapte les conseils.</p>

                </div>
            </div>


            <!--div class="col-lg-4 col-sm-6">
                <div class="single-pricing">
                    <h3>Bilan BioElectronique de Vincent</h3>
                    <h2>100<Sub>€</Sub></h2>
                    <span>1h00</span>
                    <h4>Détail</h4>

                    <ul>
                        <li>- Salives</li>
                        <li>- Urines</li>
                    </ul>

                    <p>Mesure des paramètres bioélectronique de la salives et des urines du matin. Interprétations et ou redressement</p>

                </div>
            </div-->

        </div>
    </div>
</section>
<!-- End Pricing -->

<!-- Online Price -->
<!--div class="online-price-area pb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 pr-0">
                <div class="online-price-list">
                    <ul>
                        <li>
                            Questions par zoom
                            <span class="one">15.00 €</span>
                            <span class="two">15 Minutes</span>
                        </li>

                        <li>
                            Consultations par zoom
                            <span class="one">50.00 €</span>
                            <span class="two">60 Minutes</span>
                        </li>


                    </ul>
                </div>
            </div>

            <div class="col-lg-6 pl-0">
                <div class="online-price-img"></div>
            </div>
        </div>
    </div>
</div-->
<!-- End Online Price -->

<!-- Footer -->
<app-footer-style-one></app-footer-style-one>
<!-- End Footer -->
