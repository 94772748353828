<!-- Footer -->
<section class="footer-area pt-100 pb-70">
    <div class="container">
        <div class="row">

            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <a routerLink="/"><img src="assets/img/logo.png" alt="Image"></a>
                    <p>Ma priorité : vous rendre autonome dans une bienveillance et écoute active.</p>

                    <p><strong><span style="color: darkred">Votre premier interloculteur de santé reste votre MEDECIN TRAITANT</span></strong></p>
                </div>
            </div>


            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <h3>Quelques services</h3>

                    <ul>
                        <li>Alimentation</li>
                        <li><a routerLink="/bev-service">Bilan bioelectronique de Vincent</a></li>
                        <li><a routerLink="/cc-service">Cohérence Cardiaque</a></li>
                        <li><a routerLink="/bach-service">Fleurs de Bach et quantiques olfactifs</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <h3>Liens importants</h3>

                    <ul>
                        <li><a routerLink="/">Accueil </a></li>
                        <li><a routerLink="/faq">FAQ</a></li>
                        <!--li><a routerLink="/terms-conditions">CGU</a></li>
                        <li><a routerLink="/privacy-policy">Politique de confidentialité</a></li-->
                        <li><a routerLink="/mentions-legales">Mentions légales et RGPD</a></li>
                    </ul>

                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <h3>Contact</h3>
                    <p>En cas de besoin urgent vous pouvez me laissez un message 24/7.</p>
                    <ul>
                        <li>
                            <span>Tel: </span><a href="tel:{{contactTel}}">{{contactTel}}</a>
                        </li>
                        <li>
                            <span>Email: </span><a href="mailto:{{contactEmail}}">{{contactEmail}}</a>
                        </li>

                    </ul>
                    <!--ul class="open-day">
                        <li>
                            <a href="https://www.kalae.com/fr_FR/praticiens/sane-gueye-cesson" target="_blank"><span style="color: darkred">PRENDRE UN RENDEZ-VOUS</span></a>
                        </li>
                    </ul-->
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Footer -->

<!-- Copyright -->
<div class="copy-right-area">
    <div class="container">
        <p>Copyright @2022 <strong>{{name}}</strong>.</p>
    </div>
</div>
<!-- End Copyright -->

<!-- Go Top -->
<div class="go-top">
    <i class="bx bx-chevrons-up"></i>
    <i class="bx bx-chevrons-up"></i>
</div>
<!-- End Go Top -->
