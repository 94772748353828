<!-- Header -->
<header class="header-area header-area-two">

    <!-- Top Header -->
    <div class="top-header top-header-two">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-3 col-sm-4">
                    <ul class="header-left-content">
                        <li><a href="https://www.facebook.com/profile.php?id=100086216010581" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                        <li><a href="tel:{{contactTel}}" target="_blank"><i class="bx bxl-whatsapp"></i></a></li>

                        <li>
                            <a href="" onclick="Calendly.initPopupWidget({url: 'https://calendly.com/hyginat?hide_gdpr_banner=1'});return false;">
                            <i class="bx bx-calendar"></i></a></li>


                        <!--li><a href="https://www.kalae.com/fr_FR/praticiens/sane-gueye-cesson" target="_blank"><i class="bx bx-calendar"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li-->
                    </ul>
                </div>

                <div class="col-lg-9 col-sm-8">
                    <ul class="header-right-content">
                        <li>
                            <i class="flaticon-clock"></i>
                            Lundi et Mercredi
                            <span>18H:00-20H:00</span>
                        </li>
                        <li>
                            <i class="flaticon-clock"></i>
                            Samedi et Dimanche
                            <span>09H:00-15H:00</span>
                        </li>

                        <li>
                            <i class="flaticon-call"></i>
                            Appelez-moi
                            <a href="tel:{{contactTel}}">{{contactTel}}</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <!-- Start Top Header -->

    <!-- Navbar -->
    <div class="navbar-area">
        <div class="mobile-nav">
            <div class="container">
                <a routerLink="/" class="logo"><img src="assets/img/logo.png" alt="Logo"></a>
            </div>
        </div>

        <div class="main-nav">
            <div class="container">
                <nav class="navbar navbar-expand-md">
                    <a class="navbar-brand" routerLink="/"><img src="assets/img/logo.png" alt="Logo"></a>

                    <div class="collapse navbar-collapse mean-menu">

                        <ul class="navbar-nav m-auto">
                            <!--li class="nav-item">
                                <a href="javascript:void(0)" class="nav-link">Home <i class="bx bx-chevron-down"></i></a>

                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home One</a></li>

                                    <li class="nav-item"><a routerLink="/home-two" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Two</a></li>

                                    <li class="nav-item"><a routerLink="/home-three" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Three</a></li>
                                </ul>
                            </li>

                            <li class="nav-item">
                                <a href="javascript:void(0)" class="nav-link">Pages <i class="bx bx-chevron-down"></i></a>

                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About</a></li>

                                    <li class="nav-item">
                                        <a href="javascript:void(0)" class="nav-link">Doctors <i class="bx bx-chevron-right"></i></a>

                                        <ul class="dropdown-menu">
                                            <li class="nav-item"><a routerLink="/doctors" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Doctors</a></li>

                                            <li class="nav-item"><a routerLink="/doctor-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Doctor Details</a></li>
                                        </ul>
                                    </li>

                                    <li class="nav-item"><a routerLink="/appointment" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Appointment</a></li>

                                    <li class="nav-item">
                                        <a href="javascript:void(0)" class="nav-link">Services <i class="bx bx-chevron-right"></i></a>

                                        <ul class="dropdown-menu">
                                            <li class="nav-item"><a routerLink="/services-style-one" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Services Style One</a></li>

                                            <li class="nav-item"><a routerLink="/services-style-two" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Services Style Two</a></li>

                                            <li class="nav-item"><a routerLink="/services-style-three" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Services Style Three</a></li>

                                            <li class="nav-item"><a routerLink="/services-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Service Details</a></li>
                                        </ul>
                                    </li>

                                    <li class="nav-item"><a routerLink="/testimonials" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Testimonials</a></li>

                                    <li class="nav-item">
                                        <a href="javascript:void(0)" class="nav-link">Departments <i class="bx bx-chevron-right"></i></a>

                                        <ul class="dropdown-menu">
                                            <li class="nav-item"><a routerLink="/departments" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Departments</a></li>

                                            <li class="nav-item"><a routerLink="/department-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Department Details</a></li>
                                        </ul>
                                    </li>

                                    <li class="nav-item"><a routerLink="/pricing" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Pricing</a></li>

                                    <li class="nav-item">
                                        <a href="javascript:void(0)" class="nav-link">User <i class="bx bx-chevron-right"></i></a>

                                        <ul class="dropdown-menu">
                                            <li class="nav-item"><a routerLink="/my-account" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">My Account</a></li>

                                            <li class="nav-item"><a routerLink="/login" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Login</a></li>

                                            <li class="nav-item"><a routerLink="/register" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Register</a></li>

                                            <li class="nav-item"><a routerLink="/recover-password" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Recover Password</a></li>
                                        </ul>
                                    </li>

                                    <li class="nav-item"><a routerLink="/faq" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">FAQ</a></li>

                                    <li class="nav-item"><a routerLink="/privacy-policy" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Privacy Policy</a></li>

                                    <li class="nav-item"><a routerLink="/terms-conditions" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Terms & Conditions</a></li>

                                    <li class="nav-item"><a routerLink="/coming-soon" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Coming Soon</a></li>

                                    <li class="nav-item"><a routerLink="/error" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">404 Error Page</a></li>
                                </ul>
                            </li>

                            <li class="nav-item">
                                <a href="javascript:void(0)" class="nav-link">Portfolio <i class="bx bx-chevron-down"></i></a>

                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a routerLink="/portfolio-two-column" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Portfolio Two Column</a></li>

                                    <li class="nav-item"><a routerLink="/portfolio-three-column" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Portfolio Three Column</a></li>

                                    <li class="nav-item"><a routerLink="/portfolio-four-column" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Portfolio Four Column</a></li>

                                    <li class="nav-item"><a routerLink="/portfolio-filter" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Portfolio Filter</a></li>

                                    <li class="nav-item"><a routerLink="/portfolio-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Portfolio Details</a></li>
                                </ul>
                            </li>

                            <li class="nav-item">
                                <a href="javascript:void(0)" class="nav-link">Shop <i class="bx bx-chevron-down"></i></a>

                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a routerLink="/products" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Products</a></li>

                                    <li class="nav-item"><a routerLink="/cart" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Cart</a></li>

                                    <li class="nav-item"><a routerLink="/checkout" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Checkout</a></li>

                                    <li class="nav-item"><a routerLink="/single-products" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Single Products</a></li>
                                </ul>
                            </li>

                            <li class="nav-item">
                                <a href="javascript:void(0)" class="nav-link">Blog <i class="bx bx-chevron-down"></i></a>

                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a routerLink="/blog-one-column" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog One Column</a></li>

                                    <li class="nav-item"><a routerLink="/blog-two-column" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Two Column</a></li>

                                    <li class="nav-item"><a routerLink="/blog-three-column" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Three Column</a></li>

                                    <li class="nav-item"><a routerLink="/blog-left-sidebar" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Left Sidebar</a></li>

                                    <li class="nav-item"><a routerLink="/blog-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Details</a></li>
                                </ul>
                            </li-->

                            <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Accueil</a></li>
                            <!--li class="nav-item"><a routerLink="/services-style-one" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Services</a></li>
                            <li class="nav-item"><a routerLink="/blog-three-column" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Articles</a></li-->
                            <li class="nav-item"><a routerLink="/pricing" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Tarifs</a></li>
                            <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact</a></li>
                        </ul>

                        <!--div class="others-option">
                            <div class="option-item">
                                <i class="search-btn bx bx-search"></i>
                                <i class="close-btn bx bx-x"></i>
                                <div class="search-overlay search-popup">
                                    <div class='search-box'>
                                        <form class="search-form">
                                            <input class="search-input" name="search" placeholder="Search" type="text">
                                            <button class="search-button" type="submit"><i class="bx bx-search"></i></button>
                                        </form>
                                    </div>
                                </div>
                            </div>

                            <div class="cart-icon">
                                <a routerLink="/cart"><i class="bx bx-cart"></i> <span>0</span></a>
                            </div>
                        </div-->
                    </div>
                </nav>
            </div>
        </div>

        <!--div class="others-option-for-responsive">
            <div class="container">
                <div class="dot-menu">
                    <div class="inner">
                        <div class="circle circle-one"></div>
                        <div class="circle circle-two"></div>
                        <div class="circle circle-three"></div>
                    </div>
                </div>

                <div class="container">
                    <div class="option-inner">
                        <div class="others-option justify-content-center d-flex align-items-center">
                            <div class="option-item">
                                <i class="search-btn bx bx-search"></i>
                                <i class="close-btn bx bx-x"></i>
                                <div class="search-overlay search-popup">
                                    <div class='search-box'>
                                        <form class="search-form">
                                            <input class="search-input" name="search" placeholder="Search" type="text">
                                            <button class="search-button" type="submit"><i class="bx bx-search"></i></button>
                                        </form>
                                    </div>
                                </div>
                            </div>

                            <div class="cart-icon">
                                <a routerLink="/cart"><i class="bx bx-cart"></i> <span>0</span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div-->
    </div>
    <!-- End Navbar -->

</header>
<!-- End Header -->
